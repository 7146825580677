<template>
  <el-form-item label="載具類別" required>
    <el-select
      v-model="typeModel"
      placeholder="載具類別"
      class="w-full"
      @change="numModel = ''"
    >
      <el-option
        v-for="opt in CARRIER_TYPES"
        :key="opt.key"
        :value="opt.key"
        :label="opt.name"
        >{{ opt.name }}</el-option
      >
    </el-select>
  </el-form-item>
  <el-form-item
    v-if="needNum"
    label="載具編號"
    :rules="rules"
    :prop="numPropName"
  >
    <el-input v-model="numModel" placeholder="請輸入載具編號" />
  </el-form-item>
</template>

<script setup>
import { ElSelect, ElOption, ElFormItem, ElInput } from 'element-plus';
import { useVModel } from '@vueuse/core';
import { defineEmits, defineProps, computed, reactive } from 'vue';
import { CARRIER_TYPES } from '@/modules/invoice/Config.js';
const props = defineProps({
  numPropName: {
    type: String,
    default: 'CarrierNum',
  },
  typePropName: {
    type: String,
    default: 'CarrierType',
  },
  type: {
    type: String,
    default: '',
  },
  num: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:type', 'update:num']);

const typeModel = useVModel(props, 'type', emit);
const numModel = useVModel(props, 'num', emit);

typeModel.value = '-1';
const needNum = computed(() => {
  return typeModel.value === '0' || typeModel.value === '1';
});
const rules = reactive({
  validator: (rule, value, callback) => {
    if (!needNum.value) {
      callback();
    } else if (!value) {
      callback(new Error('請填寫載具號碼'));
    } else if (
      typeModel.value === '0' &&
      new RegExp(/^\/[A-Z0-9+\-.]{7}$/).test(value) == false
    ) {
      callback(new Error('格式錯誤，請以"/"開頭，並全部大寫。'));
    } else if (
      typeModel.value === '1' &&
      new RegExp(/^[A-Z]{2}\d{14}$/).test(value) == false
    ) {
      callback(new Error('格式錯誤，請使用2碼大寫英字+14碼數字。'));
    } else {
      callback();
    }
  },
  trigger: 'blur',
});
</script>
