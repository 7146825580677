<template>
  <el-form-item label="統一編號" required :rules="rules" :prop="propName">
    <el-input v-model="model" placeholder="送出後無法更改，請務必確認" />
  </el-form-item>
</template>

<script setup>
import { ElFormItem, ElInput } from 'element-plus';
import { useVModel } from '@vueuse/core';

import { defineEmits, defineProps, ref, reactive } from 'vue';
const props = defineProps({
  propName: {
    type: String,
    default: 'BuyerUBN',
  },
  ubn: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(['update:ubn']);
const model = useVModel(props, 'ubn', emit);
const regex = new RegExp(/^\d{8}$/);
const rules = reactive([
  { required: true, message: '請輸入公司統一編號', trigger: 'blur' },
  {
    validator: (rule, value, callback) => {
      if (!regex.test(value)) {
        callback(new Error('限8碼純數字。'));
      } else {
        callback();
      }
    },
    trigger: 'blur',
  },
]);
</script>
