export const TEMPS = {
  Category: '類型',
  BuyerName: '買受人名稱',
  BuyerUBN: '統一發票',
  BuyerAddress: '買受人地址',
  CarrierType: '載具類型',
  CarrierNum: '載具號碼',
  LoveCode: '捐贈碼',
};

export const TYPES = [
  {
    name: '個人發票',
    key: 'B2C',
    fields: [
      'Category',
      'BuyerName',
      'BuyerAddress',
      'CarrierType',
      'CarrierNum',
    ],
  },
  {
    name: '公司發票',
    key: 'B2B',
    fields: ['Category', 'BuyerName', 'BuyerAddress', 'BuyerUBN'],
  },
  {
    name: '捐贈',
    key: 'DONATE',
    fields: ['Category', 'BuyerName', 'BuyerAddress', 'LoveCode'],
  },
];

export const CARRIER_TYPES = [
  {
    name: '無載具',
    key: '-1',
  },
  {
    name: '手機條碼載具',
    key: '0',
  },
  {
    name: '自然人憑證條碼載具',
    key: '1',
  },
  {
    name: 'ezPay 電子發票載具',
    key: '2',
  },
];

export const DONATES = [
  {
    name: '財團法人中華民國兒童癌症基金會',
    key: '88888',
  },
  {
    name: '財團法人台灣兒童暨家庭扶助基金會',
    key: '8585',
  },
  {
    name: '財團法人國際單親兒童文教基金會',
    key: '11111',
  },
  {
    name: '財團法人中華民國兒童燙傷基金會',
    key: '0995',
  },
  {
    name: '捐贈其他受捐單位',
    key: 'other',
  },
];
export default {
  TYPES,
  CARRIER_TYPES,
  DONATES,
};
