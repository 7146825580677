<template>
  <div v-loading="loading" class="">
    <div class="item-center flex">
      <slot></slot>
      <ElInput
        v-model.trim="form.code"
        placeholder="請輸入折扣代碼"
        @focus="errorMsg = ''"
      >
        <template #append>
          <el-button :disabled="!form.code" size="default" @click="submit"
            >兌換</el-button
          >
        </template>
      </ElInput>
    </div>

    <p v-if="errorMsg" class="text-danger mt-2">
      折扣碼無效，可能是輸入錯誤或是已無法再使用。
    </p>
  </div>
</template>
<script setup>
import { defineEmits, inject } from 'vue';
import { ElInput } from 'element-plus';

const emit = defineEmits(['update']);
const { form, errorMsg, loading, submit } = inject('exchangeData');
</script>
