<template>
  <div class="triangle">
    <font-awesome-icon
      :icon="['fas', 'check']"
      class="text-white text-sm relative z-10 icon"
    />
    <div class="bg"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.triangle
    position: absolute
    bottom: 0
    right: 0
    width: 32px
    height: 32px
    overflow: hidden
    .bg
        position: absolute
        width: 360%
        height: 360%
        @apply top-0 left-0 bg-danger rotate-45
    .icon
        position: absolute
        bottom: 2px
        right: 2px
</style>
