<template>
  <el-form
    ref="invoiceFormRef"
    :disabled="disabled"
    label-position="top"
    label-width="100px"
    class="mt-6 max-w-2xl"
    :class="{ disabled }"
    size="large"
    :model="form"
    :rules="rules"
  >
    <div class="gap-x-6 md:block">
      <el-form-item>
        <el-radio-group v-model="form.type" @change="updateCategory">
          <el-radio v-for="opt in TYPES" :key="opt.key" :label="opt.key">
            {{ opt.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </div>

    <div class="grid grid-cols-2 gap-x-6 mt-4 md:block">
      <el-form-item label="買受人名稱" prop="BuyerName">
        <el-input v-model="form.BuyerName" />
      </el-form-item>

      <el-form-item label="買受人地址" prop="BuyerAddress">
        <el-input
          v-model="form.BuyerAddress"
          placeholder="請輸入地址"
          required
        />
      </el-form-item>
      <template v-if="form.type === 'B2B'">
        <UBNField v-model:ubn="form.BuyerUBN" />
      </template>

      <!-- b2c -->
      <template v-if="form.type === 'B2C'">
        <CarrierField
          v-model:type="form.CarrierType"
          v-model:num="form.CarrierNum"
        />
      </template>
      <template v-if="form.type === 'DONATE'">
        <DonateField v-model:code="form.LoveCode" />
      </template>
    </div>
  </el-form>
</template>

<script setup>
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElRadio,
  ElRadioGroup,
} from 'element-plus';
import { inject, defineProps } from 'vue';
import CarrierField from './fields/Carrier.vue';
import DonateField from './fields/Donate.vue';

import UBNField from './fields/UBN.vue';
import { TYPES } from '@/modules/invoice/Config.js';
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { form, updateCategory, rules, invoiceFormRef } = inject('invoiceData');
</script>
