<template>
  <div>
    <div class="text-black text-lg mb-2">發票資訊</div>
    <div>買受人：{{ form.BuyerName }}</div>
    <div>買受人地址 :{{ form.BuyerAddress }}</div>
    <div>聯絡信箱：{{ form.email }}</div>
    <div>電子發票 :{{ info }}</div>
  </div>
</template>

<script setup>
import { inject } from 'vue';
const { form, info } = inject('invoiceData');
</script>
