<template>
  <div>
    <div class="bg-primary-light px-6 py-6 rounded -mx-6 -mt-6">
      <Exchange-Input />
    </div>

    <!-- 目前可用的折扣券 -->
    <div v-loading="loading" class="mt-8">
      <div class="space-y-2">
        <Coupon-Item
          v-for="row in rows"
          :key="row.id"
          :row="row"
          selectable
          :disabled="
            selected.length >= COUPON_LIMIT && selected.indexOf(row.id) < 0
          "
          :selected="selected.indexOf(row.id) > -1"
          @toggle="toggle(row.id)"
        />
        <!-- <Coupon selectable></Coupon>
            <Coupon selectable special></Coupon> -->
      </div>
      <div class="flex justify-center mt-4">
        <el-button type="primary" size="large" @click="submit"
          >套用折扣</el-button
        >
      </div>
    </div>

    <!-- 目前沒有可用的折扣券 -->
    <div v-if="loading === false && !rows.length" class="mt-8">
      <div class="text-lg">您目前沒有可用的折扣券，請輸入折扣代碼</div>
      <img src="@/assets/imgs/role-upset.png" alt="" class="mt-8 mx-auto" />
    </div>
  </div>
</template>
<script setup>
import { reactive, provide, onMounted, defineEmits, inject, watch } from 'vue';
import { COUPON_LIMIT } from '@/utils/Config';
import CouponItem from './_Item.vue';
import ExchangeInput from './_Exchange.vue';
import _ from 'lodash';

import useExchange from './useExchange';
const emit = defineEmits(['update']);

const { rows, fetcher, loading } = inject('couponData');
const { form } = inject('shopData');
const exchangeData = useExchange({ rows });
provide('exchangeData', exchangeData);

//選擇氣的資料
const selected = reactive([]);
const toggle = (id) => {
  selected.splice(0, selected.length, ..._.xor(selected, [id]));
};
const submit = () => {
  form.qPonIds = [...selected];
  emit('update');
};

watch(
  () => _.cloneDeep(form.qPonIds),
  (val, old) => {
    if (!_.isEqual(val, old)) {
      selected.splice(0, selected.length, ...val);
    }
  },
  {
    immediate: true,
  },
);
onMounted(() => {
  fetcher();
});
</script>
