import { reactive, ref } from 'vue';
import qPon from '@/api/Coupon.js';
import { ElMessage } from 'element-plus';
export default ({ rows }) => {
  const loading = ref(null);

  const form = reactive({ code: '' });

  const errorMsg = ref('');

  const submit = () => {
    loading.value = true;
    return qPon
      .exchange({ code: form.code })
      .then((res) => {
        if (res.data?.errorCode) {
          errorMsg.value = res.data.message;
        } else {
          ElMessage({ type: 'success', message: '成功兌換折扣券' });
          rows.push(res.data);
        }
      })
      .finally((res) => {
        loading.value = false;
        return res;
      });
  };

  return { form, submit, loading, errorMsg };
};
