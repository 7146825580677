<template>
  <div>
    <div class="text-primary">
      <div class="font-bold text-xl">{{ row.title }}</div>
      <div class="mt-1">{{ row.info }}</div>
    </div>
    <div class="text-gray text-sm mt-4 space-y-2">
      <div class="flex justify-between">
        <span>金額小計</span>
        <span>NT$ {{ row.subtotal }}</span>
      </div>
      <div class="flex justify-between">
        <span>折抵金額</span>
        <span>-NT${{ row.discount }}</span>
      </div>
      <div class="flex justify-between pt-4 items-end">
        <div>實付金額</div>
        <div class="text-2xl text-danger">NT${{ row.total }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';
const { detail: row } = inject('shopData');
</script>
