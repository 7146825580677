<template>
  <el-form-item label="捐贈單位 ">
    <el-select
      v-model="codeTemp"
      placeholder="捐贈單位"
      class="w-full"
      @change="update"
    >
      <el-option
        v-for="opt in DONATES"
        :key="opt.key"
        :label="opt.name"
        :value="opt.key"
        >{{ opt.name }}</el-option
      >
    </el-select>
  </el-form-item>
  <el-form-item ref="codeRef" label="捐贈碼" :rules="rules" :prop="propName">
    <el-input
      v-model="model"
      placeholder="捐贈碼"
      :disabled="codeTemp !== 'other'"
      :maxlength="7"
      :minlength="3"
    />
  </el-form-item>
</template>

<script setup>
import { ElSelect, ElOption, ElFormItem, ElInput } from 'element-plus';
import { useVModel } from '@vueuse/core';
import { DONATES } from '@/modules/invoice/Config.js';
import { defineEmits, defineProps, ref, reactive, toRefs } from 'vue';
const props = defineProps({
  propName: {
    type: String,
    default: 'LoveCode',
  },
  code: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:code']);
const model = useVModel(props, 'code', emit);
const regex = new RegExp(/^\d{3,7}$/);
const rules = reactive([
  { required: true, message: '請輸入捐贈碼', trigger: 'blur' },
  {
    validator: (rule, value, callback) => {
      if (!regex.test(value)) {
        callback(new Error('限 3~7 碼純數字。'));
      } else {
        callback();
      }
    },
    trigger: 'blur',
  },
]);

const codeTemp = ref(null);
const { code } = toRefs(props);
const codeRef = ref(null);
if (code.value) {
  model.value = code.value;
  codeTemp.value =
    DONATES.findIndex((x) => x.key === code.value) < 0 ? 'other' : code.value;
} else {
  codeTemp.value = DONATES[0].key;
  model.value = DONATES[0].key;
}

const update = () => {
  codeRef.value.clearValidate();
  if (codeTemp.value !== 'other') {
    model.value = codeTemp.value;
  } else {
    model.value = null;
  }
};
</script>
