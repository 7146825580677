import Axios from './http';
const prefix = '/consumer/v1';

export default class qPon {
  static async myCoupon(params) {
    return Axios(prefix + '/payment/qPon/myCoupon', {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1 && res.data.dataType?.length) {
        res.data.dataType = res.data.dataType.map((x) => {
          return {
            ...x,
            id: x.type,
          };
        });
      }
      return res;
    });
  }
  static async exchange(params) {
    return Axios(prefix + '/payment/qPon/exChange', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
