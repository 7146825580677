<template>
  <div class="flex item-center space-x-2">
    <div
      class="coupon rounded py-1 px-2 overflow-hidden relative border flex-grow bg-white"
      :class="[
        {
          selectable: selectable,
          selected: selectable && selected,
          'pointer-events-none opacity-40': disabled,
        },
      ]"
      @click="toggle"
    >
      <div class="absolute top-0 left-0 w-[70%] h-full flex">
        <div class="flex-grow" :style="{ backgroundColor: row.color }"></div>
        <svg
          viewBox="0 0 58.747147 54.749901"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:svg="http://www.w3.org/2000/svg"
        >
          <g
            id="g123"
            clip-path="url(#clip0_3847_125016)"
            transform="translate(-100)"
          >
            <path
              id="path121"
              d="m -65.4662,40.4437 c 8.7008,-6.1161 19.2049,0.7857 33.5318,-12.7659 5.6623,-5.3559 2.3098,-8.9004 9.0857,-13.3274 6.6967,-4.37555 16.02091,1.0591 18.57755,-19.3804 2.65349,-21.2011 35.64875,-12.009 54.14345,-21.4995 9.9602,-5.1115 18.6646,2.4012 26.9981,-0.9662 12.522,-5.06 10.7508,-14.7836 19.7676,-15.3275 8.277,-0.4993 7.781,6.0997 26.25,5.0747 31.732,-1.7615 43.675,30.95584 30.694,41.19989 -12.511,9.87291 2.589,19.25101 -5.793,25.33321 -10.071,7.3073 -27.132,2.1983 -24.329,16.0663 0.925,4.5806 10.184,15.1033 4.336,21.1629 -7.946,8.2325 8.709,20.8301 -7.575,28.0988 -22.4133,10.0044 -11.681,15.2724 -24.2947,20.2254 -9.6336,3.783 -17.0169,3.726 -28.0544,2.486 -11.4261,-1.283 -20.0324,-8.824 -25.194,-5.529 -14.028,8.957 -0.0487,12.014 -11.063,18.069 -6.5004,3.573 -16.4407,-1.597 -27.30323,-0.672 -12.58017,1.07 -25.21077,7.329 -32.04617,3.982 -9.9035,-4.849 -4.0921,-13.094 -8.6408,-16.27 -10.384,-7.251 -50.7835,-35.6207 -54.7142,-41.6992 -5.3271,-8.2392 -6.4776,-18.937 5.7592,-22.144 10.9703,-2.8761 13.0346,-7.3166 19.8641,-12.1171 z"
              :fill="row.color"
            />
          </g>
          <defs id="defs128">
            <clipPath id="clip0_3847_125016">
              <rect width="243.33299" height="54.749901" x="0" y="0" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="flex relative z-10">
        <div class="w-1/2">
          <div class="text-white text-sm">{{ row.desc }}</div>
          <div class="mt-2 text-xs scale-90 origin-bottom-left">
            <div class="text-yellow">{{ row.code }}</div>
            <div class="text-white mt-1">{{ row.deadline }} 截止</div>
          </div>
        </div>
        <div class="w-1/2 flex items-end justify-end space-x-1 pb-4">
          <template v-if="row.type === 'price'">
            <span>折</span>
            <span class="text-danger text-xs mb-[1px]">NT</span>
            <span class="text-danger text-3xl font-bold">{{ row.amount }}</span>
          </template>
          <template v-else>
            <span>送</span>
            <span class="text-danger text-3xl font-bold">{{ row.amount }}</span>
            <span class="text-danger text-xs mb-[1px]">天</span>
          </template>
        </div>
      </div>
      <!-- expired -->
      <div
        v-if="row.status > 0"
        class="text-xs absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 z-20 flex justify-center items-center"
      >
        已失效
      </div>
      <transition name="fade">
        <TriangleCheck v-show="selectable && selected"></TriangleCheck>
      </transition>
    </div>
    <div
      v-if="withDelete"
      class="flex items-center cursor-pointer hover:opacity-75"
      @click="remove"
    >
      <font-awesome-icon
        :icon="['fas', 'times-circle']"
        class="text-danger text-2xl"
      />
    </div>
  </div>
</template>

<script>
import TriangleCheck from '@/components/TriangleCheck.vue';
export default {
  components: {
    TriangleCheck,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withDelete: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    special: {
      type: Boolean,
      default: false,
    },
    // expired: {
    //   type: Boolean,
    //   default: false,
    // },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle', 'remove'],
  setup(props, { emit }) {
    const toggle = () => {
      emit('toggle');
    };
    const remove = () => {
      emit('remove');
    };
    return { toggle, remove };
  },
};
</script>

<style lang="sass" scoped>
.coupon.selectable
  outline: 3px solid transparent
  @apply cursor-pointer m-1 duration-200
  &:hover,&.selected
    @apply border-danger
    outline: 3px solid var(--el-color-danger)
</style>
