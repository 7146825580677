<template>
  <div>
    <div class="space-y-2">
      <Item
        v-for="row in rows"
        :key="row.id"
        :row="row"
        :with-delete="true"
        @remove="remove(row.id)"
      ></Item>
    </div>
    <div class="mt-4">
      <el-button
        :disabled="rows.length >= COUPON_LIMIT"
        type="text"
        class="underline text-primary cursor-pointer"
        @click="dialog.toggle({ show: true })"
      >
        添加折扣券
      </el-button>
      <div v-if="rows.length >= COUPON_LIMIT" class="text-danger text-sm mt-1">
        你最多可使用 {{ COUPON_LIMIT }} 張 Q-pon 券
      </div>
    </div>
    <hr class="my-4" />
    <div class="">單科方案Q-pon折扣碼使用規則</div>

    <ul class="text-gray list-decimal text-xs pl-4 mt-2">
      <li>每科可使用1張Q-pon。</li>
      <li>每張Q-pon可折抵$40。</li>
      <li>每張Q-pon限使用一次。</li>
      <li>Q-pon有效期限過後無法使用</li>
    </ul>

    <el-dialog
      v-model="dialog.state.show"
      title="我的折扣券"
      center
      custom-class="!max-w-md"
    >
      <Selector @update="update"></Selector>
    </el-dialog>
  </div>
</template>
<script setup>
import { computed, inject } from 'vue';
import { ElDialog } from 'element-plus';
import Selector from './_Selector.vue';
import Item from './_Item.vue';
import useDialog from '@/hook/useDialog';

import { COUPON_LIMIT } from '@/utils/Config';

const { form } = inject('shopData');
const couponData = inject('couponData');
const dialog = useDialog();

const rows = computed(() => {
  return couponData.rows.filter((x) => form.qPonIds?.indexOf(x.id) > -1);
});

const remove = (id) => {
  const idx = form.qPonIds.findIndex((x) => x === id);
  if (idx > -1) {
    form.qPonIds.splice(idx, 1);
  }
};

const update = () => {
  dialog.toggle();
};
</script>
